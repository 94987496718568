<template>
    <div class="content">
        <h1 class="pagetitle">
            {{ management.title }}：{{ textset[management.textset][editMode] }}
        </h1>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            tag="div"
        >
            <template v-for="(data, index) in collection">
                <template v-if="field[data.field].type !== 'file'">
                    <h2 :key="`head${index}`" :class="$style.head">
                        {{ field[data.field].head }}
                    </h2>
                    <div :key="`div${index}`">
                        <Input
                            :data="data"
                            :flag-validated-immediate="flagValidatedImmediate"
                            :input="input[data.field]"
                            @input="input[data.field] = $event"
                            @address="input[field[data.field].connect] = $event"
                        />
                    </div>
                </template>
                <template v-else>
                    <h2 :key="`head${index}`" :class="$style.head">
                        {{ field[data.field].head }}{{ fileIndex[index] + 1 }}
                    </h2>
                    <div :key="`div${index}`">
                        <InputFile
                            :key="`file${index}`"
                            :option="file[fileIndex[index]].option"
                            @data="file[fileIndex[index]].data = $event"
                            @delete-flag="file[fileIndex[index]].deleteFlag = $event"
                            @file-name="$_setFileName(fileIndex[index], $event)"
                        />
                    </div>
                </template>
            </template>
            <div class="buttonArea">
                <div>
                    <Button
                        :disabled="invalid"
                        type="next"
                        @action="buttonNext()"
                    />
                </div>
                <div>
                    <Button
                        text="戻る"
                        type="textlink"
                        @action="buttonBack()"
                    />
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    "use strict";

    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            detail,
        ],
        "props": {
            "editMode": {
                "type": String,
                "required": true,
            },
            "id": {
                "type": String,
                "required": false,
                "default": "",
            },
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "input": {},
            };
        },
        created() {
            if (this.$store.getters.getTransceiver.input) {
                this.$_reset();
                this.$_setData();
                this.input = JSON.parse(JSON.stringify(this.$store.getters.getTransceiver.input));
                this.readData = this.input;
            }
            else {
                this.$_created();
            }
        },
        "methods": {
            // idと入力データをvuex storeにset
            setStore() {
                const setData = {
                    "id": "",
                    "input": {},
                };
                const id = this.id;

                if (id) {
                    setData.id = id;
                }
                for (const data of this.collection) {
                    setData.input[data.field] = this.input[data.field];
                }
                this.$store.dispatch("setTransceiver", setData);
            },
            buttonBack() {
                this.$router.back();
            },
            buttonNext() {
                this.setStore();
                if (this.editMode === "create") {
                    this.$router.push({
                        "name": `${this.name}_create_confirm`,
                    });
                }
                else if (this.editMode === "update") {
                    this.$router.push({
                        "name": `${this.name}_update_confirm`,
                    });
                }
            },
        },
    };
</script>

<style lang="scss" module>
    textarea {
        display: block;
    }

    .head {
        margin-bottom: .5em;
        &:not(:first-of-type) {
            margin-top: 2em;
        }
    }
</style>
